import React, { useState } from "react";
import { Spinner, Tables } from "../../../../../../Components";
import { useTranslation } from 'react-i18next';
import { GetParams } from "../../../../../../Helper";
import { GetAgentLeadAssigmentHistory } from "../../../../../../Services";
import { useEffect } from "react";


const parentTranslationPath = 'LeadAssignAgentsTracking';
const translationPath = '';

export const LeadAssignAgentsTracking = () => {
    const [isLoadingAssignAgentsTracking, setIsLoadingAssignAgentsTracking] = useState(false);
    const { t } = useTranslation(parentTranslationPath);
    const [filter, setFilter] = useState({
        pageSize: 25,
        pageIndex: 0,
        agentName: null,
        assignDate: null,
        lastActivityTypeName: null,
        lastActivityDate: null,
    });

    const [data, setData] = useState({});

    const GetAgentLeadAssigment = async () => {
        setIsLoadingAssignAgentsTracking(true);
        const relatedLeadId = +GetParams('id');

        const body = {
            ...filter, pageIndex: filter.pageIndex + 1,
        };

        const res = await GetAgentLeadAssigmentHistory(relatedLeadId, { pageSize: body.pageSize, pageIndex: body.pageIndex });
        if (!(res && res.status && res.status !== 200)) {
            setData({
                result: (res && res.result) || [],
                totalCount: (res && res.totalCount) || 0,
            });
        } else {
            setData({
                result: [],
                totalCount: 0,
            });
        }
        setIsLoadingAssignAgentsTracking(false);
    }

    useEffect(() => {
        GetAgentLeadAssigment();
    }, [GetAgentLeadAssigmentHistory])

    const onPageIndexChanged = () => {
        setFilter((item) => ({
            ...item,
            pageIndex: 0,
            agentName: null,
            assignDate: null,
            lastActivityTypeName: null,
            lastActivityDate: null,
        }));
    };

    const onPageSizeChanged = (pageSize) => {
        setFilter((item) => ({
            ...item,
            pageIndex: 0,
            pageSize,
            agentName: null,
            assignDate: null,
            lastActivityTypeName: null,
            lastActivityDate: null,
        }));
    };
    return (<>
        <div className='d-flex-column'>
            <Spinner isActive={isLoadingAssignAgentsTracking} />
            <div className='activities-top-section'>
                <div className='d-flex-column'>
                </div>
            </div>
            <div className='w-100 px-3'>
                <Tables
                    data={(data && data.result) || []}
                    headerData={[
                        {
                            id: 1,
                            label: 'agent-name',
                            input: 'agentName',
                            isSortable: false,
                        },
                        {
                            id: 6,
                            label: 'assign-by',
                            input: 'assignBy',
                            isSortable: false,
                        },
                        {
                            id: 2,
                            label: 'assign-date',
                            input: 'assignDate',
                            isSortable: false,
                            isDate: true,
                        },
                        {
                            id: 3,
                            label: 'assign-time',
                            input: 'assignDate',
                            isDate: true,
                            dateFormat: ' hh:mm A',
                        },
                        {
                            id: 4,
                            label: 'assign_type',
                            input: 'assignType',
                        },
                        {
                            id: 5,
                            label: 'type_name',
                            input: 'typeName',
                        },
                    ]}
                    defaultActions={[]}
                    onPageIndexChanged={onPageIndexChanged}
                    onPageSizeChanged={onPageSizeChanged}
                    itemsPerPage={filter.pageSize}
                    activePage={filter.pageIndex}
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                    totalItems={(data && data.totalCount) || 0}
                />
            </div>
        </div>
    </>)
}
