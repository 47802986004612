import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { PageTitle, FBTextField, FBAutocomplete, FBLabelPosition } from "../../../../Components";
import MultiStepForm from "./MultiStepForm";
import { useSelectedTheme, useTranslate } from "../../../../Hooks";

// Styles
import useStyles from "./styles";

// Icons
import { UserOneIcon } from "../../../../assets/icons";

function AddIndividualForm() {
  const styles = useStyles();

  const [inputValue, setInputValue] = useState("");
  const [inputErrorvalue, setInputErrorvalue] = useState("");
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const [autocompleteErrorValue, setAutocompleteErrorValue] = useState(null);

  const [autocompleteMultiValue, setAutocompleteMultiValue] = useState([]);
  const [autocompleteStringOptionMultiValue, setAutocompleteStringOptionMultiValue] = useState([]);

  const options = [
    { label: "Option 1", value: 1 },
    { label: "Option 2", value: 2 },
    { label: "Option 3", value: 3 },
    { label: "Option 4", value: 4 },
  ];

  const { translate } = useTranslate("NewContactsView");

  const {
    theme: { palette },
  } = useSelectedTheme();

  return (
    <Box>
      <PageTitle
        title={translate("add-individual-contact-page-title")}
        subTitle={translate("add-individual-contact-page-subtitle")}
        hideDivider
      />
      
      <MultiStepForm />

      <hr />

      <h1>Form Builder Controller</h1>

      <hr />

      <FBTextField
        label="First name"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder="Enter your name"
        required
        labelPosition={FBLabelPosition.SIDE}
        helperInfo="helper text for the input field"
        fieldCustomClasses={styles.fieldCustomClasses}
      />

      <hr />

      <FBTextField
        label="First name"
        value={inputErrorvalue}
        onChange={(e) => setInputErrorvalue(e.target.value)}
        placeholder="Enter your name"
        required
        labelPosition={FBLabelPosition.SIDE}
        error
        errorMessage="This is an error message."
        fieldCustomClasses={styles.fieldCustomClasses}
      />

      <hr />

      <FBAutocomplete
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.value === value.value}
        options={options}
        value={autocompleteValue}
        onChange={(event, newValue) => {
          setAutocompleteValue(newValue);
        }}
        label="Select an Option"
        placeholder="Select"
        helperInfo="helper text Pick an option from the list"
        required
        labelPosition={FBLabelPosition.SIDE}
        startIcon={
          <UserOneIcon
            width="20"
            height="20"
            fill={palette.foreground.quarterary}
          />
        }
      />

      <hr />
      {/* this case when option not object item */}
      <FBAutocomplete
        options={["Option 1", "Option 2", "Option 3", "Option 4"]}
        value={autocompleteErrorValue}
        onChange={(event, newValue) => {
          setAutocompleteErrorValue(newValue);
        }}
        label="Select an Option"
        placeholder="Select"
        helperInfo="helper text for the autocomplete field"
        error
        errorMessage="This is an error message on autocomplete component."
        required
        labelPosition={FBLabelPosition.SIDE}
      />

      <hr />

      <FBAutocomplete
        getOptionLabel={(option) => option.label} // Display the label of the option
        getOptionSelected={(option, value) => option.value === value.value} // Comparing based on 'value'
        limitTags={2}
        options={[
          { label: "Option 1", value: "option1", avatarPath: "https://www.w3schools.com/howto/img_avatar.png" },
          { label: "Option 2", value: "option2" },
          { label: "Option 3", value: "option3", avatarPath: "https://www.w3schools.com/howto/img_avatar.png" },
          { label: "Option 4", value: "option4" },
          { label: "Option 5", value: "option5", avatarPath: "https://www.w3schools.com/howto/img_avatar.png" },
          { label: "Option 6", value: "option6" },
          { label: "Option 7", value: "option7", avatarPath: "https://www.w3schools.com/howto/img_avatar.png" },
          { label: "Option 8", value: "option8" },
          { label: "Option 9", value: "option9", avatarPath: "https://www.w3schools.com/howto/img_avatar.png" },
          { label: "Option 10", value: "option10" },
        ]}
        showAvatar={
          <UserOneIcon
            width="10"
            height="10"
            fill={palette.foreground.quinary}
          />
        }
        value={autocompleteMultiValue} // Currently selected values (array when multiple is enabled)
        onChange={(event, newValue) => {
          console.log("Selected Value:", newValue);
          setAutocompleteMultiValue(newValue); // Update the state when selection changes
        }}
        label="Select Multi Options"
        placeholder="Select"
        helperInfo="helper text Pick an option from the list"
        required
        labelPosition={FBLabelPosition.SIDE}
        multiple // Enable multiple
        startIcon={
          <UserOneIcon
            width="20"
            height="20"
            fill={palette.foreground.quarterary}
          />
        }
      />

      <hr />

      <FBAutocomplete
        limitTags={2}
        options={["Option 1", "Option 2", "Option 3", "Option 4"]}
        showAvatar
        value={autocompleteStringOptionMultiValue}
        onChange={(event, newValue) => {
          console.log("Selected Value:", newValue);
          setAutocompleteStringOptionMultiValue(newValue);
        }}
        label="Select Multi Options error state"
        placeholder="Select"
        helperInfo="helper text Pick an option from the list"
        required
        labelPosition={FBLabelPosition.SIDE}
        multiple
        error
        errorMessage="This is an error message on multi autocomplete component."
      />
    </Box>
  );
}

export default AddIndividualForm;
