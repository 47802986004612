import clsx from "clsx";
import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  Avatar,
  IconButton,
  MenuItem,
  Menu,
} from "@material-ui/core";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import { useNewLayoutShared } from "../../../Contexts/NewLayoutSharedContext";
import { useSelectedTheme, useTranslate } from "../../../Hooks";
import { getDownloadableLink, truncateString } from "../../../Helper";
import {
  CustomIconButton,
  CustomBadge,
  CustomCopyToClipboard,
  PropertyPlanBadges,
  PropertyRating,
} from "../..";

// Icons
import {
  ArrowUpRight,
  EmptyUnitGalleryIcon,
  MoreVertIcon,
  ListIcon,
} from "../../../assets/icons";

// Styles
import useStyles from "./styles";

const PropertyCard = ({ item }) => {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();
  const { translate } = useTranslate("NewContactsView");
  const history = useHistory();

  const { setIsAddTaskDialogOpen } = useNewLayoutShared();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setAnchorEl({
      top: rect.top + 25,
      left: rect.left + 15,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = [
    {
      label: "Add task",
      icon: (
        <ListIcon width="16" height="16" fill={palette.foreground.quarterary} />
      ),
      handleOnclick: () => setIsAddTaskDialogOpen(true),
    },
  ];

  const imageUrls =
    item?.allpropertyImages?.map((image) => ({
      id: image.fileId,
      img: getDownloadableLink(image.fileId),
    })) || [];

  const CarouselSlider = () => {
    return imageUrls?.map((image) => (
      <SplideSlide key={image.id}>
        <div>
          <div>
            <img
              alt={`Image ${image.id}`}
              height={240}
              id={image.id.toString()}
              src={image.img || ""}
              width={339}
            />
          </div>
        </div>
      </SplideSlide>
    ));
  };

  const [activeImage, setActiveImage] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const handleArrowClick = (type) => {
    setActiveImage((prevCounter) =>
      type === "Next" ? prevCounter + 1 : prevCounter - 1
    );
  };

  const ImageCarousel = () => {
    return (
      <Splide
        aria-label="Images"
        options={{
          classes: {
            arrows: "splide__arrows details-tab-arrows",
          },
          start: activeImage || 0,
        }}
        onArrowsMounted={(splide) => {
          // Add event listeners to the next and prev buttons
          const prevArrow = splide.root.querySelector(".splide__arrow--prev");
          const nextArrow = splide.root.querySelector(".splide__arrow--next");

          if (prevArrow)
            prevArrow.addEventListener("click", () => handleArrowClick("Prev"));
          if (nextArrow)
            nextArrow.addEventListener("click", () => handleArrowClick("Next"));
        }}
      >
        {CarouselSlider()}
      </Splide>
    );
  };

  return (
    <Box key={item?.id} className={styles.unitCard}>
      {item?.propertyStatus?.value && (
        <Box className={styles.status}>
          <PropertyPlanBadges PropertyPlanKey={item?.propertyStatus?.value} />
        </Box>
      )}
      <Box
        onMouseEnter={() =>
          setIsHovered((prev) => (prev !== true ? true : prev))
        }
        onMouseLeave={() =>
          setIsHovered((prev) => (prev !== false ? false : prev))
        }
        className={clsx(styles.imageWrapper, {
          [styles.imageWrapperHover]: isHovered,
        })}
      >
        {item?.allpropertyImages?.length === 1 ? (
          <Box
            className={styles.unitImage}
            style={{
              backgroundImage: `url(${getDownloadableLink(
                item?.allpropertyImages[0].fileId
              )})`,
            }}
          />
        ) : item?.allpropertyImages?.length > 1 && imageUrls?.[0]?.id ? (
          <ImageCarousel />
        ) : (
          <Box className={styles.emptyImage}>
            <EmptyUnitGalleryIcon
              width="52"
              height="52"
              fill={palette.foreground.quinary}
            />
          </Box>
        )}
      </Box>

      <Box className={styles.details}>
        <Box className={styles.header}>
          <Box
            className={styles.flexRow}
            style={{ alignItems: "center", maxWidth: "87%" }}
          >
            <Box className={styles.unitName}>
              {truncateString(item?.name, 50)}
            </Box>
            <Box
            // onClick={() => {
            // }}
            >
              <CustomIconButton
                variant="text"
                size="xs"
                boxShadow="none"
                color="secondary"
              >
                <ArrowUpRight
                  width="20"
                  height="20"
                  fill={palette.button.secondaryGray_fg}
                />
              </CustomIconButton>
            </Box>
            <Box
              className={styles.menuWrapper}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <IconButton
                aria-controls="more-button"
                aria-haspopup="true"
                aria-label="more-button"
                id="more-button"
                disableRipple
                onClick={handleClick}
                className={styles.menuIcon}
              >
                <MoreVertIcon
                  width="20"
                  height="20"
                  fill={
                    anchorEl
                      ? palette.foreground.quarterary
                      : palette.foreground.quinary
                  }
                />
              </IconButton>
              <Menu
                id="more-button"
                PaperProps={{ elevation: 0 }}
                MenuListProps={{ className: styles.customMenuList }}
                anchorReference="anchorPosition"
                anchorPosition={
                  anchorEl
                    ? { top: anchorEl.top, left: anchorEl.left }
                    : undefined
                }
                open={Boolean(anchorEl)}
                onClose={handleClose}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
              >
                {options?.map(({ label, icon, handleOnclick }) => (
                  <MenuItem
                    disableRipple
                    key={label}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleOnclick();
                      handleClose();
                    }}
                    className={styles.option}
                  >
                    {icon}
                    <span>{label}</span>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
          <Box className={styles.idSection}>
            <CustomCopyToClipboard
              data={item?.id} // copy
              childrenData={"#" + item?.id} // render
              copyDoneShowTime={1000}
            />

            <Box className={styles.propertyType}>{item?.propertyType}</Box>

            <CustomBadge
              label={item?.progress + "%" || "0%"}
              SizeVariant={"small"}
              BackgroundColor={
                palette.utility[item?.progress >= 50 ? "brand_50" : "error_50"]
              }
              BorderColor={
                palette.utility[
                  item?.progress >= 50 ? "brand_200" : "error_200"
                ]
              }
              Color={
                palette.utility[
                  item?.progress >= 50 ? "brand_700" : "error_700"
                ]
              }
            />
          </Box>
          <label className={styles.countryText}>
            {" "}
            {item?.community?.lookupItemName +
              (item?.sub_community?.lookupItemName
                ? ", " + item?.sub_community?.lookupItemName
                : "")}
          </label>
          <PropertyRating rating={item?.propertyRating} />
        </Box>

        <Divider className={styles.divider} />

        <Box className={styles.footer}>
          <Box className={styles.flexRow}>
            <label className={clsx(styles.font400, styles.secondary)}>
              Owner:
            </label>
            <Box className={styles.owner}>
              <Box className={styles.containerItemAvatar}>
                {item?.property_owner && item?.property_owner !== "N/A" && (
                  <Avatar className={styles.avatarStyle}>
                    {item?.property_owner?.slice(0, 2).toUpperCase()}
                  </Avatar>
                )}
              </Box>

              <span style={{ fontWeight: 600 }} className={styles.primary}>
                {item?.property_owner}
              </span>
            </Box>
          </Box>
          <Box className={styles.flexRow2}>
            <label className={clsx(styles.font500, styles.secondary)}>
              Creation On:
            </label>
            <Box className={styles.dateInfo}>
              <label className={clsx(styles.font500, styles.primary)}>
                {moment(item?.createdOn).format("DD MMM YYYY")}
              </label>
              <span className={clsx(styles.font400, styles.tertiary)}>
                {truncateString(item?.createdBy, 10)}
              </span>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

PropertyCard.propTypes = {
  item: PropTypes.object.isRequired,
};

export default PropertyCard;
