import React, { useState, useEffect, useCallback } from "react";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  GetAllMainNumberProcessRecordsAPI,
  ApproveMainNumberProcessRecordsAPI,
} from "../../../../../Services";
import {
  GetParams,
  GlobalHistory,
  showError,
  showSuccess,
} from "../../../../../Helper";
import { Spinner } from "../../../../../Components";
import { ImportDetailsConvoloCardComponent } from "./ImportDetailsCardComponent/ImportDetailsConvoloCardComponent";

const translationPath = "";
export const ImportDetailsConvoloView = ({}) => {
  const { t } = useTranslation("ImportDetailsView");

  const [fileDetails, setFileDetails] = useState({
    totalFailed: 0,
    totalSuccess: 0,
    totalImportFiles: 0,
    data: [],
    isCompleted: false,
  });

  const [importId, setImportId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getProcess = useCallback(async () => {
    setIsLoading(true);
    const response = await GetAllMainNumberProcessRecordsAPI(importId);
    if (!(response && response.status && response.status !== 200)) {
      const totalImportFiles =
        (response && Array.isArray(response) && response?.length) || 0;
      const totalFailed = response.filter((s) => s.isFailed === true);
      const totalSuccess = response.filter((s) => s.isFailed === false);
      setFileDetails({
        totalFailed: totalFailed.length,
        totalSuccess: totalSuccess.length,
        totalImportFiles: totalImportFiles,
        data: response,
        isCompleted: response && response.some((f) => f.isCompleted),
      });
    }
    setIsLoading(false);
  }, [importId]);

  const approvedHandler = async () => {
    GlobalHistory.goBack();
    const res = await ApproveMainNumberProcessRecordsAPI(importId);
    if (!(res && res.status && res.status !== 200)) {
      if (res) showSuccess(t(`${translationPath}approved-successfully`));
      else showError(t(`${translationPath}approval-failed`));
    }
    showError(t(`${translationPath}approval-failed`));
  };

  useEffect(() => {
    setImportId(GetParams("id"));
  });
  useEffect(() => {
    if (importId) {
      getProcess();
    }
  }, [importId]);
  
  return (
    <div className="import-details-view">
      <Spinner isActive={isLoading} />
      <div className="header-section">
        <div className="item-section">
          <div className="box-wrapper">
            <span className="mdi mdi-clock-time-three mdi-36px c-blue-lighter px-2" />
            <div className="d-inline-flex-column px-2">
              <span className="">
                {t(`${translationPath}total-extensions`)}
                <span className="px-2 c-blue-lighter fw-bold">
                  {fileDetails?.totalImportFiles}
                </span>
              </span>

              <span>{`${t(`${translationPath}convolo-import-status`)}`}</span>
              <span className="c-blue-lighter fw-bold">
                {(fileDetails &&
                  fileDetails?.isCompleted &&
                  t(`${translationPath}approved`)) ||
                  t(`${translationPath}pending`)}
              </span>
            </div>
          </div>
        </div>
        <div className="item-section">
          <div className="box-wrapper d-flex-column">
            <span className="mdi mdi-check mdi-24px icon-circle px-2" />
            <div className="d-inline-flex-column px-2">
              <span>{t(`${translationPath}number-of-success`)}</span>
              <span className="c-success d-flex-center fw-bold fz-22px">
                {fileDetails && fileDetails?.totalSuccess}
              </span>
            </div>
            <Button
              className="btns w-100 mx-0 mt-3 bg-success"
              disabled={
                (fileDetails && fileDetails?.isCompleted) ||
                (fileDetails && fileDetails?.totalSuccess === 0)
              }
              onClick={approvedHandler}
            >
              <span className="c-white">
                {t(`${translationPath}approve-valid-fields`)}
              </span>
            </Button>
          </div>
        </div>
        <div className="item-section">
          <div className="box-wrapper">
            <span className="mdi mdi-message-alert mdi-flip-h mdi-36px c-danger px-2" />
            <div className="d-inline-flex-column px-2">
              <span>{t(`${translationPath}number-of-failed`)}</span>
              <span className="d-flex-center c-danger fz-22px fw-bold">
                {fileDetails && fileDetails?.totalFailed}
              </span>
            </div>
          </div>
        </div>
      </div>
      <ImportDetailsConvoloCardComponent
        isLoading={isLoading}
        data={fileDetails.data}
        translationPath={translationPath}
      />
    </div>
  );
};
