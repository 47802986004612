import { config } from "../../config/config";
import { HttpServices } from "../../Helper";

const GetExtensionsByMainNumberAPI = async (mainNumberId ,body) => {
  const result = await HttpServices.post(
    `${config.server_address}/Identity/BranchNumber/GetExtensionsByMainNumber?mainNumberId=${mainNumberId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const UpdateBranchExtensionNumberAPI = async (branchExtensionId ,body) => {
  const result = await HttpServices.put(
    `${config.server_address}/Identity/BranchNumber/UpdateBranchExtensionNumber?branchExtensionId=${branchExtensionId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const BulkUpdateExtensionStatusAPI = async (isActive ,body) => {
  const result = await HttpServices.put(
    `${config.server_address}/Identity/BranchNumber/BulkUpdateExtensionStatus?isActive=${isActive}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};


const UpdateBranchMainNumberAPI = async (mainNumberId , number ,body) => {
  const result = await HttpServices.put(
    `${config.server_address}/Identity/BranchNumber/UpdateBranchMainNumber?mainNumberId=${mainNumberId}&number=${number}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const ImportExtensionsNumbersAPI = async (mainNumberId ,{file}) => {
  console.log('%%%%%%' , file  ) ; 
  const body = new FormData();
  body.append('file', file);
  const result = await HttpServices.post(
    `${config.server_address}/Identity/BranchNumber/ImportExtensionsNumbers?mainNumberId=${mainNumberId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

  const GetAllMainNumberProcessRecordsAPI = async (processId) => {
  const result = await HttpServices.get(
    `${config.server_address}/Identity/BranchNumber/GetAllMainNumberProcessRecords?processId=${processId}`
    
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const ApproveMainNumberProcessRecordsAPI = async (processId) => {
  const result = await HttpServices.post(
    `${config.server_address}/Identity/BranchNumber/ApproveMainNumberProcessRecords?processId=${processId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const AddBranchMainNumberAPI = async (branchId , mainNumber ) => {
  const result = await HttpServices.post(
    `${config.server_address}/Identity/BranchNumber/AddBranchMainNumber?branchId=${branchId}&mainNumber=${mainNumber}`,{}
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const AddBranchExtensionNumberAPI = async (mainNumberId , extensionNumber ) => {
  const result = await HttpServices.post(
    `${config.server_address}/Identity/BranchNumber/AddBranchExtensionNumber?mainNumberId=${mainNumberId}&extensionNumber=${extensionNumber}`
    
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllExtensionsNumbersByMainIdAPI = async (mainNumberId ) => {
  const result = await HttpServices.get(
    `${config.server_address}/Identity/BranchNumber/GetAllExtensionsNumbersByMainId?mainNumberId=${mainNumberId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllMainNumbersByBranchIdAPI = async (branchId) => {
  const result = await HttpServices.get(
    `${config.server_address}/Identity/BranchNumber/GetAllMainNumbersByBranchId?branchId=${branchId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};



export {
  GetExtensionsByMainNumberAPI , 
  UpdateBranchExtensionNumberAPI ,
  BulkUpdateExtensionStatusAPI ,
  UpdateBranchMainNumberAPI ,
  ImportExtensionsNumbersAPI ,
  GetAllMainNumberProcessRecordsAPI ,
  ApproveMainNumberProcessRecordsAPI ,
  AddBranchMainNumberAPI ,
  AddBranchExtensionNumberAPI ,
  GetAllExtensionsNumbersByMainIdAPI ,  
  GetAllMainNumbersByBranchIdAPI     
};
