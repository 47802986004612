import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Box, IconButton } from "@material-ui/core";
import { useSelectedTheme, useTranslate } from "../../../Hooks";
import { ActionsEnum, ContactPreferenceEnum } from "../../../Enums";
import { showError, WhatsAppMessage } from "../../../Helper";
import { MaqsamTokenServices } from "../../../Services";
import { DialogComponent } from "../../DialogComponent/DialogComponent";
import { ContactPreferenceComponents } from "./ContactPreferencePopups";

// Icons
import {
  WhatsappIcon,
  EmailIcon,
  SMSIcon,
  PhoneIcon,
} from "../../../assets/icons";

// Styles
import useStyles from "./styles";

const parentTranslationPath = "ContactsView";
const translationPath = "";

function SocialLinks({ gap, activeItem }) {
  const styles = useStyles({ gap });

  const {
    theme: { palette },
  } = useSelectedTheme();

  const [isOpenContactsActionDialog, setisOpenContactsActionDialog] =
    useState(false);

  const [detailedCardAction, setdetailedCardAction] = useState(() => ({
    actionEnum: "",
    item: "",
  }));

  const [isOpenMaqsamiframe, setisOpenMaqsamiframe] = useState(false);

  const { translate } = useTranslate("ContactsView");
  const checkIfSensitiveField = (contactPreferenceType, item, contact_type) => {
    const isEmail = contactPreferenceType === "Email";
    const isSMS = contactPreferenceType === "SMS";
    const isWhatsApp = contactPreferenceType === "WhatsApp";
    const isCall = contactPreferenceType === "Call";
    if (+contact_type === 1) {
      if (
        (isEmail && item?.email_address?.email === "********") ||
        (isSMS && item?.mobile?.phone === "********") ||
        (isWhatsApp &&
          (item?.whatsapp_mobile?.phone === "********" ||
            item?.mobile?.phone === "********")) ||
        (isCall && item?.mobile?.phone === "********")
      ) {
        return false;
      }
    } else if (+contact_type === 2) {
      if (
        (isEmail && item?.general_email?.email === "********") ||
        (isSMS && item?.landline_number?.phone === "********") ||
        (isWhatsApp && item?.landline_number?.phone === "********") ||
        (isCall && item?.landline_number?.phone === "********")
      ) {
        return false;
      }
    }

    return true;
  };

  const MaqsamTokenServicesAPI = useCallback(async (mobile) => {
    const result = await MaqsamTokenServices();
    if (result) {
      setisOpenMaqsamiframe(true);
      var iframe = document.getElementById("maqsamiframe");
      if (iframe !== null) {
        let token = result;
        iframe.setAttribute(
          "src",
          `https://portal.maqsam.com/autologin?auth_token=${token};continue_path=/phone/dialer#autodial=${mobile}`
        );
      }
    }
  }, []);

  const detailedCardActionClicked = useCallback(
    (actionEnum, item) => (event) => {
      event.stopPropagation();
      if (actionEnum === "smsSolid" || "emailSolid") {
        setisOpenContactsActionDialog(true);
      }
      setdetailedCardAction({ actionEnum, item });
      if (actionEnum === "whatsappSolid") {
        const el = document.createElement("a");

        if (item?.mobile?.phone || item?.["mobile.phone"]) {
          el.href = WhatsAppMessage(
            item?.whatsapp_mobile?.phone ||
              item?.mobile?.phone ||
              item?.["whatsapp_mobile.phone"] ||
              item?.["mobile.phone"]
          );
          el.target = "blank";
          el.click();
        } else showError(translate(`${translationPath}Failure-Open-WhatsApp`));
      }
      if (actionEnum === "phoneSolid") {
        MaqsamTokenServicesAPI(
          (item && item?.mobile && item?.mobile?.phone) ||
            item?.["mobile.phone"] || // done access for table view with flatten for table view
            (item &&
              item?.contacts_person &&
              item?.contacts_person?.[0]?.mobile) ||
            null
        );
      }
      // eslint-disable-next-line no-console
    },
    []
  );

  return (
    <>
      <Box className={styles.socialLinksContainer}>
        {(
          activeItem?.contactPreference || activeItem?.contact_preference
        )?.findIndex(
          (el) => ContactPreferenceEnum.call.key === el.lookupItemId
        ) !== -1 &&
          checkIfSensitiveField(
            "Call",
            activeItem,
            activeItem?.contact_type_id
          ) && (
            <IconButton
              className={styles.socialLinkIcon}
              disableRipple
              variant="contained"
              onClick={detailedCardActionClicked(
                ActionsEnum.phoneSolid.key,
                activeItem
              )}
            >
              <PhoneIcon
                width="20"
                height="20"
                fill={palette.button.lightGray_fg}
              />
            </IconButton>
          )}
        {(
          activeItem?.contactPreference || activeItem?.contact_preference
        )?.findIndex(
          (el) => ContactPreferenceEnum.sms.key === el.lookupItemId
        ) !== -1 &&
          checkIfSensitiveField(
            "SMS",
            activeItem,
            activeItem?.contact_type_id
          ) && (
            <IconButton
              className={styles.socialLinkIcon}
              disableRipple
              variant="contained"
              onClick={detailedCardActionClicked(
                ActionsEnum.smsSolid.key,
                activeItem
              )}
            >
              <SMSIcon
                width="20"
                height="20"
                fill={palette.button.lightGray_fg}
              />
            </IconButton>
          )}

        {(
          activeItem?.contactPreference || activeItem?.contact_preference
        )?.findIndex(
          (el) => ContactPreferenceEnum.email.key === el.lookupItemId
        ) !== -1 &&
          checkIfSensitiveField(
            "Email",
            activeItem,
            activeItem?.contact_type_id
          ) && (
            <IconButton
              className={styles.socialLinkIcon}
              disableRipple
              variant="contained"
              onClick={detailedCardActionClicked(
                ActionsEnum.emailSolid.key,
                activeItem
              )}
            >
              <EmailIcon
                width="20"
                height="20"
                fill={palette.button.lightGray_fg}
              />
            </IconButton>
          )}

        {(
          activeItem?.contactPreference || activeItem?.contact_preference
        )?.findIndex(
          (el) => ContactPreferenceEnum.whatsapp.key === el.lookupItemId
        ) !== -1 &&
          checkIfSensitiveField(
            "WhatsApp",
            activeItem,
            activeItem?.contact_type_id
          ) && (
            <IconButton
              className={styles.socialLinkIcon}
              disableRipple
              variant="contained"
              onClick={detailedCardActionClicked(
                ActionsEnum.whatsappSolid.key,
                activeItem
              )}
            >
              <WhatsappIcon
                width="20"
                height="20"
                fill={palette.button.lightGray_fg}
              />
            </IconButton>
          )}
      </Box>
      <ContactPreferenceComponents
        isOpen={isOpenContactsActionDialog}
        actionEnum={detailedCardAction.actionEnum}
        isOpenChanged={() => setisOpenContactsActionDialog(false)}
        activeItem={detailedCardAction.item}
      />

      <DialogComponent
        saveText="confirm"
        titleText="Call Server"
        saveType="button"
        maxWidth="sm"
        dialogContent={
          <div className="d-flex-column-center">
            <iframe
              className="maqsamiframeClass"
              id="maqsamiframe"
              width="500"
              height="600"
              allow="microphone"
            ></iframe>
          </div>
        }
        saveClasses="btns theme-solid bg-danger w-100 mx-2 mb-2"
        isOpen={isOpenMaqsamiframe}
        onCloseClicked={() => setisOpenMaqsamiframe(false)}
        onCancelClicked={() => setisOpenMaqsamiframe(false)}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
    </>
  );
}

SocialLinks.propTypes = {
  gap: PropTypes.number,
  activeItem: PropTypes.object,
};

SocialLinks.defaultProps = {
  gap: 8,
};

export default SocialLinks;
