import React from "react";
import { Box } from "@material-ui/core";

import { useSelectedTheme } from "../../../Hooks";
import { CustomBadge } from "../CustomBadge";

// Styles
import useStyles from "./styles";

const PropertyPlanBadges = ({ PropertyPlanKey }) => {
  const {
    theme: { palette },
  } = useSelectedTheme();

  const styles = useStyles();

  const PropertyPlanEnum = {
    completed: {
      key: "Completed",
      value: "Completed",
      backgroundColor: palette.utility.success_50,
      borderColor: palette.utility.success_200,
      color: palette.utility.success_700,
    },
    "Pre-Launch": {
      key: "Pre-Launch",
      value: "Pre-Launch",
      backgroundColor: "rgba(248, 249, 252, 0.5)",
      borderColor: palette.utility.orange_200,
      color: palette.utility.orange_700,
    },
    default: {
      key:
        PropertyPlanKey.charAt(0).toUpperCase() +
        PropertyPlanKey.slice(1).toLowerCase(),
      value:
        PropertyPlanKey.charAt(0).toUpperCase() +
        PropertyPlanKey.slice(1).toLowerCase(),
      backgroundColor: "rgba(248, 249, 252, 0.5)",
      borderColor: "rgba(255, 255, 255, 0.4)",
      color: "#101828",
    },
  };

  const propertyPlan =
    PropertyPlanEnum[PropertyPlanKey] || PropertyPlanEnum.default;

  return (
    <Box className={styles.badgeSection}>
      <CustomBadge
        key={propertyPlan.key}
        SizeVariant="large"
        label={propertyPlan.key}
        BackgroundColor={propertyPlan.backgroundColor}
        BorderColor={propertyPlan.borderColor}
        Color={propertyPlan.color}
        Style={{
          backdropFilter:
            PropertyPlanKey !== "completed" ? "blur(4px)" : "unset",
        }}
      />
    </Box>
  );
};

export default PropertyPlanBadges;
