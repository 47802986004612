import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ButtonBase } from "@material-ui/core";
import Joi from "joi";
import {
  DialogComponent,
  PhonesComponent,
  Spinner,
  SwitchComponent,
} from "../../../../../../../../../../../Components";
import {
  showError,
  GlobalTranslate,
  showSuccess,
} from "../../../../../../../../../../../Helper";
import { UpdateBranchExtensionNumberAPI } from "../../../../../../../../../../../Services";

export const EditExtension = ({
  parentTranslationPath,
  translationPath,
  isOpen,
  onClose,
  onSave,
  activeItem,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState({
    saveExtension: false,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [helperText, setHelperText] = useState({
    extensionNumber: "",
  });

  const [extensionInfo, setExtensionInfo] = useState({
    branchExtensionId: null,
    extensionNumbers: null,
    branchExtensionId: null,
    isActive: false,
  });
  const schema = Joi.object({
    extensionNumber: Joi.string()
      .required()
      .messages({
        "string.base": t(`${translationPath}extension-is-required`),
        "string.empty": t(`${translationPath}extension-is-required`),
        "string.required": t(`${translationPath}extension-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(extensionInfo);

  const saveHandler = useCallback(async () => {
    setIsLoading((item) => ({ ...item, saveExtension: true }));
    const res = await UpdateBranchExtensionNumberAPI(
      extensionInfo?.branchExtensionId,
      {
        extensionNumber: extensionInfo.extensionNumbers,
        isActive: extensionInfo.isActive,
      }
    );
    console.log("# res # ", res);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}edit-extension-succssfuly`)) ;
      setIsLoading((item) => ({ ...item, saveExtension: false }));
      onSave(res);
    } else {
      showError(t(`${translationPath}edit-extension-faild`)) ;
      setIsLoading((item) => ({ ...item, saveExtension: false }));
    }
  }, [extensionInfo]);

  const convoloNumberValidation = (extensionNumber) => {
    if (extensionNumber.length < 11) {
      setHelperText({
        ...helperText,
        extensionNumber: t(
          `${translationPath}enter-extension-number-at-least-11-digits`
        ),
      });
      return true;
    } else if (extensionNumber.length > 14) {
      setHelperText({
        ...helperText,
        extensionNumber: t(
          `${translationPath}enter-extension-number-no-more-than-14-digits`
        ),
      });
      return true;
    } else {
      setHelperText({
        ...helperText,
        extensionNumber: "",
      });
      return false;
    }
  };
  
  useEffect(() => {
    if (activeItem) {
      setExtensionInfo((item) => ({
        ...item,
        branchExtensionId: activeItem?.extensionNumberId,
        extensionNumbers: activeItem?.extensionNumbers,
        isActive: activeItem?.isActive,
      }));
    }
  }, [activeItem, isOpen]);

  return (
    <>
      <DialogComponent
        saveText={GlobalTranslate.t("Shared:confirm")}
        disableBackdropClick
        titleText={
          !activeItem
            ? t(`${translationPath}add-extension`)
            : t(`${translationPath}edit-extension`)
        }
        saveClasses={"btns theme-solid bg-primary"}
        saveType="button"
        maxWidth="sm"
        dialogContent={
          <div className="d-flex-column-center">
            <Spinner isActive={isLoading.saveExtension} isAbsolute />
            <div className="w-100 mr-1-reversed mt-3">
              <PhonesComponent
                idRef="extensionNumberRef"
                labelClasses="Requierd-Color"
                labelValue="extension-number"
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                value={extensionInfo?.extensionNumbers || ""}
                helperText={
                  (helperText?.extensionNumber !== "" &&
                    t(helperText?.extensionNumber)) ||
                  ""
                }
                error={helperText?.extensionNumber !== ""}
                inputPlaceholder="enter-valid-extension-number"
                isSubmitted={isSubmitted}
                onInputChanged={(value) => {
                  let newValue = value;
                  newValue = newValue.replaceAll(" ", "");
                  convoloNumberValidation(newValue);
                  setExtensionInfo((item) => ({
                    ...item,
                    extensionNumbers: newValue,
                  }));
                }}
              />
            </div>
            <div className="w-100 mr-1-reversed mt-3">
              <SwitchComponent
                idRef="statusRef"
                isChecked={extensionInfo.isActive}
                themeClass="theme-line"
                labelValue={(extensionInfo.isActive && "active") || "inactive"}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChangeHandler={(event, isChecked) =>
                  setExtensionInfo((item) => ({
                    ...item,
                    isActive: isChecked,
                  }))
                }
              />
            </div>
          </div>
        }
        isOpen={isOpen}
        saveIsDisabled={
          isLoading.saveExtension ||
          extensionInfo.extensionNumbers === null ||
          extensionInfo.extensionNumbers === "" ||
          helperText?.extensionNumber !== ""
        }
        onSaveClicked={() => saveHandler()}
        onCancelClicked={onClose}
        translationPath={translationPath.confirm}
        onCloseClicked={onClose}
      />
    </>
  );
};
