import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  PlatinumIcon,
  GoldIcon,
  SilverIcon,
  BronzeIcon,
} from "../../../assets/icons";

const useStyles = makeStyles((theme) => ({
  flexRow: {
    display: "flex",
    gap: "4px",
    alignItems: "center",
  },
  percentage: {
    color: theme.palette.text.brand_tertiary,
  },
}));

const getPropertyIcon = (propertyRating) => {
  if (propertyRating >= 90 && propertyRating <= 100) {
    return <PlatinumIcon />;
  } else if (propertyRating >= 80 && propertyRating < 90) {
    return <GoldIcon />;
  } else if (propertyRating >= 70 && propertyRating < 80) {
    return <SilverIcon />;
  } else {
    return <BronzeIcon />;
  }
};

const PropertyRating = ({ rating }) => {
  const classes = useStyles();

  return (
    <Box className={classes.flexRow}>
      {getPropertyIcon(rating)}
      <Box>
        {rating}
        <span className={classes.percentage}>%</span>
      </Box>
    </Box>
  );
};

export default PropertyRating;
