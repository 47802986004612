
import React, { useEffect, useState  , useCallback} from "react";
import { Tables  , Spinner } from '../../../../../../../../../../Components'  ; 
import { ReactComponent as EditIcon } from "../../../../../../../../../../assets/images/defaults/edit-01.svg";
import { useTranslation } from "react-i18next";
import { EditExtension } from './ImportAndAddExtension/EditExtension' ; 


export const ConvoloExtensionsTable = ({
  parentTranslationPath,
  translationPath,
  extensions ,
  branchId ,
  onAddOrEditExtensions ,
  reloadExtensionsNumbersByMainId ,
  checkedDetailed ,
  setCheckedDetailed  ,
  isLoading
  
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isOpenExtensionDialog, setIsOpenExtensionDialog] = useState(false);
  const [activeItem ,setActiveItem ] = useState(null); 


  
  function formatNumber(number) {
    let mainPart = number.slice(0, -3);
    
    let lastThree = number.slice(-3);
    return {mainPart , lastThree};
  }


  function formatNumber(num) {
    let str = num.toString();
    let countryCode = str.slice(0, 3); // "971"
    let middlePart = str.slice(3, 4); // "0" (converted to "-2")
    let remainingPart = str.slice(4); // "9876543" (should be "12345678" in your example)
    let newRemainingPart = remainingPart.substring(0, remainingPart?.length-3);
    let lastThree = remainingPart.slice(-3);


    return <span className="">{`${countryCode}-${middlePart}-${newRemainingPart}`}<span style={{ color: '#3B72D9' }} >{`(${lastThree})`}</span></span>
    
  }

 

      const getIsSelected = useCallback(
        (itemIndex) =>
          checkedDetailed.findIndex(
            (item) =>
              item.extensionNumberId === itemIndex.extensionNumberId || item === itemIndex
          ) !== -1,
        [checkedDetailed]
      );

      const onSelectClicked = useCallback((element, itemIndex) => {
          setCheckedDetailed((items) => {
            const index = items.findIndex(
              (item) => item.extensionNumberId === element.extensionNumberId
            );
            if (index !== -1) items.splice(index, 1);
            else items.push(element);
            return [...items];
          });
        }, []);

    useEffect(() => 
       {
        setCheckedDetailed([]) ; 
           
       }, [reloadExtensionsNumbersByMainId?.mainNumberId]);

        
    


  return (
    <>
      <div className="">
         <Spinner isActive={Object.values(isLoading).some((t) => t)} isAbsolute/>
        <Tables
          data={extensions.results || []}
          headerData={[
            {
              id: 1,
              label: "mainNumber",
              input: "mainNumber",
            },
            {
              id: 2,
              label: "extensionNumbers",
              input: "extensionNumbers",
              component: (item) => (
                <>
                  {(item &&
                    item.extensionNumbers &&
                    item.extensionNumbers.length > 5  && (
                      formatNumber(item.extensionNumbers)
                    )) || <span className="fw-bold">{item.extensionNumbers || ''}</span>}
                
        
                </>
              )
            },
            {
              id: 3,
              label: "isActive",
              input: "isActive",
              component: (item) => (
                <>
                  {(item &&
                    item.isActive &&
                   (
                    <span style={{ color: '#079455' }}> {t(`${translationPath}active`)}</span>
                    )) || <span style={{ color: '#DB504A' }}> {t(`${translationPath}inactive`)}</span>}
                
        
                </>
              )
            },
            {
              id: 5,
              label: "agentName",
              input: "agentName",
            },
            {
              id: 3,
              label: "action",
              input: "action",
              component: (item) => (
                <>
                <span className="d-flex-center"
                 onClick={() => {
                  setIsOpenExtensionDialog(true);
                    setActiveItem(item);
                  }}
                  title={t("edit-extension")}
                >
                  <EditIcon />
                </span>
                
        
                </>
              )
            },

          ]}
          selectAllOptions={
            (
              extensions &&  
              extensions.results  &&
              extensions.results.length && {
              selectedRows: checkedDetailed,
              getIsSelected,
              disabledRows: [],
              onSelectClicked,
            }) ||
            undefined
          }
              HideSellectAll
              TotalSelect={checkedDetailed && checkedDetailed.length  }
          defaultActions={[]}
          itemsPerPage={1000}
          activePage={1}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          totalItems={extensions.totalCount || 0}
        />
         <EditExtension
                  isOpen={isOpenExtensionDialog}
                  onSave={(status) => {
                    setIsOpenExtensionDialog(false);
                    onAddOrEditExtensions() ;
                  }}
                  onClose={() => {
                    setIsOpenExtensionDialog(false);
                  }}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  activeItem={activeItem}
                />
      </div>
    </>
  );
};
