/* eslint-disable no-nested-ternary */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import "./PendingField.scss"
import Button from '@material-ui/core/Button';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import i18next from 'i18next';
import { ButtonBase, Tooltip } from '@material-ui/core';
import {
  ActionsEnum,
  ContactTypeEnum,
  LoadableImageEnum,
  UnitsOperationTypeEnum,
  UnitsStatusEnum,
} from '../../../../../Enums';
import { ShareUnitsDialog } from '../../../UnitsView/UnitsUtilities/Dialogs';
import {
  GlobalHistory,
  showError,
  showSuccess,
  sideMenuIsOpenUpdate,
  returnPropsByPermissions,
  getDownloadableLink,
} from '../../../../../Helper';
import {
  DialogComponent,
  FacebookGalleryComponent,
  LoadableImageComponant,
  PopoverComponent,
  SelectComponet,
  Spinner,
} from '../../../../../Components';
import {
  archiveUnitsPut,
  CanSetUnitAsReservedOrLeasedOrSale,
  GetFieldsNeedingApprovalForChange,
  GetListingAgentDetails,
  GetPendingUserFields,
  GetSuitableTemplateIdForUnit,
  GetUnitAvailableStatus,
} from '../../../../../Services';
import { UnitStatusDraftDialog } from './Dialogs';
import { UnitStatusAvailableDialog } from './Dialogs/UnitStatusAvailableDialog/UnitStatusAvailableDialog';
import { ArchiveState } from '../../../../../assets/json/StaticValue.json';
import { UnitsSalesPermissions } from '../../../../../Permissions';
import { ActivitiesManagementDialog } from '../../../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog';
import { CopyToClipboardComponents } from '../../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents';
import ActivityDetailsComponent from '../../../../DFMSharedComponents/ActivityDetails/ActivityDetailsComponent';
import FormattedMessage from '../../../../../SharedComponents/FormattedComponent/FormattedMessage';
import Chip from '@material-ui/core/Chip';
import { Business, Email, PhoneEnabled } from '@material-ui/icons';
import { PermissionsComponent } from '../../../../../Components';
import { useSelector } from 'react-redux';
import { UnitTemplateDialogPreviewDialog } from '../../../UnitsView/UnitsProfileManagementView/Dialogs/UnitTemplateDialog/UnitTemplateDialogPreviewDialog';
import {  UnitMPIGaugeChartComponent } from '../../UnitsSalesUtilities/CardDetailsComponent/UnitMPIGaugeChartComponent/UnitMPIGaugeChartComponent';

function CardDetailsComponent({
  activeData,
  cardDetailsActionClicked,
  reloadData,
  parentTranslationPath,
  translationPath,
  from,
  displyOpenFileButton,
  showQuickTransaction,
  onClickActiveData = () => { },
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [unitAvailableStatuses, setUnitAvailableStatuses] = useState([]);
  const [templatesProposal, setTemplatesProposal] = useState([]);
  const [activeTemplateId, setActiveTemplateId] = useState(-1);
  const [templates, setTemplates] = useState([]);
  const [activeTemplateName, setActiveTemplateName] = useState(null);
  const [isOpenTemplateDialog, setIsOpenTemplateDialog] = useState(false);
  const history = useHistory();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [addActivity, setAddActivity] = useState(false);
  const [isOpenShareUnitDialog, setIsOpenShareUnitDialog] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [isOpenDraftDialog, setIsOpenDraftDialog] = useState(false);
  const [isOpenAvailableDialog, setIsOpenAvailableDialog] = useState(false);
  const [activitydetails, setActivityDetails] = useState(false);
  const [listingAgent, setListingAgent] = useState({});
  const [activeImageIndex, setActiveImageIndex] = useState(null);
  const [makeTransactionOnUnitList, setMakeTransactionOnUnitList] = useState(
    []
  );
  const [attachedWith, setAttachedWith] = useState(null);
  const [pendingUserFields, setPendingUserFields] = useState([]);
  const [shouldDisableFields, setShouldDisableFields] = useState(false)
  const [fieldsNeedsApproval, setFieldsNeedsApproval] = useState([]);


  const loginResponse = useSelector((state) => state.login.loginResponse);

  const { t } = useTranslation(parentTranslationPath);
  const popoverRef = useRef();
  const archiveUnits = useCallback(async () => {
    await archiveUnitsPut(activeData.id);
    showSuccess(t(`${translationPath}Successarchive`));
    sideMenuIsOpenUpdate(false);
    setOpen(false);
    reloadData();
  }, [activeData.id, reloadData, t, translationPath]);
  const unitOperationSource = {
    sale: {
      key: 1,
    },
    lease: {
      key: 2,
    },
  };
  const getCanSetUnitAsReservedOrLeasedOrSale = async (newStatus) => {
    const res = await CanSetUnitAsReservedOrLeasedOrSale(
      activeData.id,
      newStatus
    );
    if (res) {
      GlobalHistory.push(
        `/home/units-sales/unit-status-management?status=${newStatus}&id=${activeData.id}&from=${from}&rowVersion=${activeData.rowVersion}`
      );
    } else {
      setSelectedStatus(null);
      showError(t(`${translationPath}cannot-change-status-description`));
    }
  };

  const onSelectChanged = async (newStatus) => {
    setSelectedStatus(newStatus);

    if (newStatus === UnitsStatusEnum.Draft.key) setIsOpenDraftDialog(true);
    else if (newStatus === UnitsStatusEnum.Available.key)
      setIsOpenAvailableDialog(true);
    else getCanSetUnitAsReservedOrLeasedOrSale(newStatus);
  };

  const getUnitAvailableStatus = useCallback(async () => {
    setIsLoading(true);
    const res = await GetUnitAvailableStatus(
      activeData.id,
      UnitsOperationTypeEnum.sale.key
    );
    if (!(res && res.status && res.status !== 200))
      setUnitAvailableStatuses(res);
    else setUnitAvailableStatuses([]);
    setIsLoading(false);
  }, [activeData.id]);
  const GetSuitableTemplateIdForUnitApi = useCallback(async (unitId) => {
    setIsLoading(
      true
    )
    let res;
    res = await GetSuitableTemplateIdForUnit(
      unitId,
      1,
      10000,
      UnitsOperationTypeEnum.sale.key
    );
    if (!((res && res.data && res.data.ErrorId) || !res))
      setTemplatesProposal((res && res.result) || []);
    else setTemplatesProposal([]);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (activeData && activeData.id) getUnitAvailableStatus();
    setSelectedStatus(null);
  }, [getUnitAvailableStatus]);

  const getDefaultUnitImage = useCallback(
    (unitType) =>
      ContactTypeEnum[unitType] && ContactTypeEnum[unitType].defaultImg,
    []
  );

  const getTransactionListForUnit = () => {
    const list = [];
    const transactionList = Object.values(UnitsStatusEnum).filter(
      (item) =>
        item.showInSelect &&
        (!item.effectedOperationType ||
          activeData.operationType === item.effectedOperationType ||
          activeData.operationType ===
          UnitsOperationTypeEnum.rentAndSale.key) &&
        unitAvailableStatuses.findIndex((element) => element === item.key) !==
        -1
    );
    transactionList.length &&
      transactionList.map((item) => {

        if (
          item &&
          item.value === 'available' &&
          returnPropsByPermissions(
            UnitsSalesPermissions.MakeTransactionOnUnitSaleToBeAvailable
              .permissionsId
          )
        )
          list.push(item);
        else if (
          item &&
          item.value === 'reserved-for-sale' &&
          returnPropsByPermissions(
            UnitsSalesPermissions.SetUnitAsReserveForSale.permissionsId
          )
        )
          list.push(item);
        else if (
          item &&
          item.value === 'sale' &&
          returnPropsByPermissions(
            UnitsSalesPermissions.SetUnitAsSale.permissionsId
          )
        )
          list.push(item);
        else if (
          item &&
          item.value === 'draft' &&
          returnPropsByPermissions(
            UnitsSalesPermissions.MakeTransactionOnUnitSaleToBeDraft
              .permissionsId
          )
        )
          list.push(item);
      });
    setMakeTransactionOnUnitList(list);
  };

  const getListingAgentDetails = async (userId) => {
    const result = await GetListingAgentDetails(userId);
    setListingAgent(result);
  };


  const getPendingUserFields = async () => {
    const unitId = activeData?.id;
    const res = await GetPendingUserFields(unitId);
    if (!((res && res.data && res.data.ErrorId) || !res)) {
      setPendingUserFields(res.pendingFields)
      return res;
    }
  };
  const getFieldsNeedingApproval = async () => {
    const userRoleIds = loginResponse?.roles.map((item) => item?.rolesId);

    const res = await GetFieldsNeedingApprovalForChange({ userRoleIds });
    if (!(res && res.status && res.status !== 200)) {
      setFieldsNeedsApproval(res);
    } else setFieldsNeedsApproval([]);
  };
  useEffect(() => {
    getPendingUserFields();
    getFieldsNeedingApproval()
  }, []);

  useEffect(() => {
    if (pendingUserFields?.includes('Status')) {
      setShouldDisableFields(true);
    } else {
      setShouldDisableFields(false);
    }
  }, [pendingUserFields]);

  useEffect(() => {
    getTransactionListForUnit();
    getListingAgentDetails(activeData.listing_agent?.id);
  }, [activeData, unitAvailableStatuses]);
  const AgentListingDetails = () => {
    return (
      <Fragment>
        <div className='p-3 cards-wrapper'>
          <div className='card-header d-flex-v-center-h-between mb-4'>
            <LoadableImageComponant
              type={LoadableImageEnum.image.key}
              classes='user-cover-image'
              alt={t(`${translationPath}user-image`)}
              src={
                (listingAgent.profileImg &&
                  getDownloadableLink(listingAgent.profileImg, 200, 20)) ||
                ContactTypeEnum.man.defaultImg
              }
              style={{ maxWidth: '50px' }}
            />
            <div className='item-wrapper px-2'>
              <span className='item-header'>
                {listingAgent.listingAgentName}
              </span>
            </div>
            <div>
              <Chip
                className='mr-1'
                color='primary'
                label={activeData.unitStatus?.value}
              />
              <Chip color='primary' label={activeData.unitType} />
            </div>
          </div>
          <div className='d-flex-column px-5'>
            {listingAgent.branchName && (
              <div className='item-wrapper mb-3 d-flex-v-center'>
                <span className='item-header px-2'>
                  <Business />
                </span>
                <span className='item-body'>{listingAgent.branchName}</span>
              </div>
            )}
            <div className='item-wrapper d-flex-v-center mb-3'>
              <span className='item-header px-2'>
                <Email />
              </span>
              <span className='item-body'>{listingAgent.email}</span>
            </div>
            <div className='item-wrapper mb-3 d-flex-v-center'>
              <span className='item-header px-2'>
                <PhoneEnabled />
              </span>
              <span className='item-body'>{listingAgent.phoneNumber}</span>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
  return (
    <div className="units-card-detaild-wrapper p-relative">
      <Spinner isActive={isLoading} isAbsolute />
      <div className="w-100 px-3 mb-3">
        {makeTransactionOnUnitList.length > 0 && (
          <SelectComponet
            idRef="unitStatusRef"
            data={makeTransactionOnUnitList || []}
            emptyItem={{
              value: null,
              text: "make-transaction",
              isHiddenOnOpen: true,
            }}
            value={selectedStatus}
            translationPathForData={translationPath}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            onSelectChanged={onSelectChanged}
            valueInput="key"
            textInput="value"
            isDisabled={shouldDisableFields}
          />
        )}
      </div>
      <div className="status-wrapper">
        <div className="">
          <LoadableImageComponant
            type={LoadableImageEnum.div.key}
            classes="user-cover-image"
            alt={t(`${translationPath}user-image`)}
            src={
              (listingAgent.profileImg &&
                getDownloadableLink(listingAgent.profileImg, 200, 20)) ||
              ContactTypeEnum.man.defaultImg
            }
          />
        </div>
        <div
          className={`body-status ${
            (activeData.unitStatus && activeData.unitStatus.classes) ||
            activeData.unitStatus ||
            "bg-warning"
          }`}
        >
          <div className="body-status-type">
            {t(`${translationPath}status`)}
          </div>
          {" : "}
          {(activeData.unitStatus &&
            activeData.unitStatus.value &&
            t(
              `${translationPath}${
                activeData.unitStatus.value === "Resale"
                  ? "sale"
                  : activeData.unitStatus.value === "New Lease"
                  ? "leased"
                  : activeData.unitStatus.value
              }`
            )) ||
            activeData.unitStatus ||
            "N/A"}
        </div>
        {shouldDisableFields && (
          <div className="information-open-file-2">
            <Tooltip
              title="This field has a pending approval for changes!"
              className="pending-field"
            >
              <span className="mdi mdi-information-outline" />
            </Tooltip>
          </div>
        )}
      </div>

      <div className="archive-bbt">
        <Button
          onClick={() => setOpen(true)}
          disabled={ArchiveState}
          className="MuiButtonBase-root MuiButton-root MuiButton-text btns-icon theme-solid mx-2"
          title={t(`${translationPath}ArchiveUnit`)}
        >
          <span className="MuiButton-label">
            <span className="mdi mdi-inbox-multiple" />
          </span>
          <span className="MuiTouchRipple-root" />
        </Button>
      </div>

      {isOpenShareUnitDialog && (
        <ShareUnitsDialog
          activeData={activeData}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isOpen={isOpenShareUnitDialog}
          onClose={() => {
            setIsOpenShareUnitDialog(false);
          }}
          unitOperationSource={unitOperationSource.sale.key}
        />
      )}
      {activeData && (
        <div className="side-menu-wrapper">
          <div>
            <div className="cards-header-wrapper mb-2">
              <LoadableImageComponant
                classes="details-img"
                imageHandler
                type={LoadableImageEnum.div.key}
                alt={t(`${translationPath}unit-image`)}
                src={
                  (activeData.allunitImages &&
                    getDownloadableLink(
                      activeData.allunitImages.fileId,
                      200,
                      20
                    )) ||
                  getDefaultUnitImage(activeData.type)
                }
              />
            </div>
            <div className="mb-3">
              {showQuickTransaction && (
                <div className="mb-3" style={{ textAlign: "center" }}>
                  <Button
                    type="button"
                    onClick={() =>
                      history.push(
                        `/home/units-sales/unit-transaction-parameters?unitRefNo=${activeData?.unit_ref_no}`
                      )
                    }
                    className={`btns theme-solid`}
                    style={{ padding: "0px 12px" }}
                  >
                    <FormattedMessage
                      id="quick-transaction"
                      path={"GlobalSuccessDialog"}
                    />
                  </Button>
                </div>
              )}
              <div className="data-schedule-button">
                {returnPropsByPermissions(
                  UnitsSalesPermissions.AddNewActivity.permissionsId
                ) && (
                  <div
                    className={`add-activity-button ${
                      addActivity ? "selected" : ""
                    }`}
                  >
                    <Button
                      onClick={() => {
                        setAddActivity(true);
                        setActivityDetails(false);
                      }}
                      className={`btns ${addActivity ? "theme-outline" : ""}`}
                    >
                      {t(`${translationPath}add-activity`)}
                    </Button>
                  </div>
                )}
                <div
                  className={`details-button ${
                    activitydetails ? "selected" : ""
                  }`}
                >
                  <Button
                    onClick={() => {
                      setActivityDetails(true);
                      setAddActivity(false);
                    }}
                    className={`btns ${activitydetails ? "theme-outline" : ""}`}
                  >
                    {t(`${translationPath}ActivityDetails`)}
                  </Button>
                </div>
              </div>
            </div>
            <div className="properety-plan d-flex-center my-2">
              {activeData.name}
            </div>
            <div className="price-wrapper">
              <div className={`for-lable ${activeData.unitOperationType}`}>
                {t(`${translationPath}for`)}
              </div>
              <div className={activeData.unitOperationType}>
                {`  ${activeData.unitOperationType}`}:
              </div>
              <div className="unit-price">
                {`   ${
                  activeData.selling_price_agency_fee
                    ? activeData.selling_price_agency_fee.salePrice ||
                      activeData.selling_price_agency_fee
                    : "N/A"
                } AED`}
              </div>
            </div>
            <div className="flat-contents-wrapper">
              {activeData.flatContent.map((subItem, subIndex) => (
                <div
                  key={`detailsFlatContentsItemRef${subIndex + 1}`}
                  className="flat-content-item"
                >
                  <span
                    className={`flat-content-icon ${subItem.iconClasses} mdi-18px`}
                  />
                  <span className="px-1">{subItem.value}</span>
                  <span>
                    {subItem.title && (
                      <span className="flat-content-text">
                        {t(`${translationPath}${subItem.title}`)}
                      </span>
                    )}
                  </span>
                </div>
              ))}
              {isOpenTemplateDialog && (
                <UnitTemplateDialogPreviewDialog
                  activeItem={activeTemplateId}
                  isOpen={isOpenTemplateDialog}
                  unitID={activeData.id}
                  maintitleText={activeTemplateName}
                  unitOwner={activeData?.lead_owner}
                  leaseUnitOwner={activeData?.lease_lead_owner}
                  isOpenChanged={() => {
                    setIsOpenTemplateDialog(false);
                    setActiveTemplateId(-1);
                  }}
                  templateName={activeTemplateName}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                />
              )}
            </div>
            <div className="id-date-wrapper pl-2 pr-2">
              <div className="created-on">
                <span className="details-icon mdi mdi-calendar mdi-16px" />
                <span>
                  <span className="details-text fw-simi-bold">
                    {t(`${translationPath}created`)}:
                  </span>
                  <span className="px-1">
                    {(activeData.createdOn &&
                      moment(activeData.createdOn)
                        .locale(i18next.language)
                        .format("DD/MM/YYYY")) ||
                      "N/A"}
                  </span>
                </span>
              </div>
              <div className="contact-id-wrapper">
                {t(`${translationPath}id`)}:
                <Tooltip title={t(`${translationPath}copy`)}>
                  <CopyToClipboardComponents
                    data={activeData.id}
                    childrenData={activeData.id}
                  />
                </Tooltip>
              </div>
            </div>
            <div className="px-3 mb-3 slider-data">
              {activeData.details &&
                activitydetails === false &&
                activeData.details.map((item, index) => {
                  return (
                    <React.Fragment key={`detailsRef${index + 1}}`}>
                      {item.value && item.title !== "owner-mobile-number" && (
                        <div
                          className="px-3 mb-2"
                          onClick={index === 0 ? onClickActiveData : () => {}}
                          style={index === 0 ? { cursor: "pointer" } : {}}
                          ref={popoverRef}
                        >
                          <span className="texts gray-primary-bold">
                            {`${t(item.title)}`}:
                          </span>
                          {item.title === "listing-agent" ? (
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                setAttachedWith(popoverRef.current)
                              }
                            >{`  ${item.value}`}</a>
                          ) : item.title === "unit-model" ? (
                            <span className="texts s-gray-primary">{`  ${item.value.propertyUnitModelName}`}</span>
                          ) : (
                            <span className="texts s-gray-primary">{`  ${item.value}`}</span>
                          )}
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              <div className="d-flex-center">
                <UnitMPIGaugeChartComponent
                  activeData={activeData}
                  unitId={activeData.id}
                  operationType={UnitsOperationTypeEnum.sale.key}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                />
              </div>
              {activitydetails && (
                <ActivityDetailsComponent
                  cardId={activeData?.id}
                  data={activeData}
                  type="unit"
                  translationPath={translationPath}
                  parentTranslationPath="UnitsView"
                />
              )}
            </div>
          </div>

          {activeData.unitStatus?.value === "available" &&
          returnPropsByPermissions(
            UnitsSalesPermissions.ShareUnits.permissionsId
          ) ? (
            <div className="side-menu-actions">
              <div className="d-flex fj-around flex-wrap">
                {displyOpenFileButton && (
                  <Button
                    className="btns theme-solid mx-2 mb-2"
                    onClick={cardDetailsActionClicked(
                      ActionsEnum.folder.key,
                      activeData
                    )}
                  >
                    <span className="icons i-folder-white" />
                    <span className="mx-2">
                      {t(`${translationPath}open-file`)}
                    </span>
                  </Button>
                )}

                <div className="shareUnit-bbt">
                  <ButtonBase
                    className="btns theme-outline"
                    onClick={() => {
                      setIsOpenShareUnitDialog(true);
                    }}
                    disabled={activeData.unitStatus?.value !== "available"}
                  >
                    <span>{t(`${translationPath}Share-Unit`)}</span>
                  </ButtonBase>
                </div>
                <div className="shareUnit-bbt">
                  <SelectComponet
                    className="hidden-select-component"
                    data={templatesProposal}
                    keyLoopBy="systemTemplateId"
                    valueInput="systemTemplateId"
                    textInput="systemTemplateName"
                    value={activeTemplateId}
                    keyValue="actionsbuttons"
                    idRef="contactsActionsRef"
                    onSelectChanged={(value) => {
                      setActiveTemplateId(value);
                      if (value !== -1) {
                        const itemIndex = templates.findIndex(
                          (item) => item.systemTemplateId === value
                        );
                        if (itemIndex !== -1) {
                          setActiveTemplateName(
                            templates[itemIndex].systemTemplateName
                          );
                        }
                        setIsOpenTemplateDialog(true);
                      }
                    }}
                    themeClass="theme-action-buttons"
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                    // wrapperClasses='bg-secondary c-white mx-2'
                    parentTranslationPath={parentTranslationPath}
                    emptyItem={{
                      value: -1,
                      text: "templateProposal",
                      isHiddenOnOpen: true,
                    }}
                    onOpen={() => {
                      setTemplatesProposal([]);
                      GetSuitableTemplateIdForUnitApi(activeData.id);
                    }}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="side-menu-actions">
              <div className="d-flex-center">
                {displyOpenFileButton && (
                  <Button
                    className="btns theme-solid mx-2 mb-2"
                    onClick={cardDetailsActionClicked(
                      ActionsEnum.folder.key,
                      activeData
                    )}
                  >
                    <span className="icons i-folder-white" />
                    <span className="mx-2">
                      {t(`${translationPath}open-file`)}
                    </span>
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      <FacebookGalleryComponent
        data={(activeData && activeData.unitImages) || []}
        isOpen={(activeImageIndex !== null && activeData && true) || false}
        activeImageIndex={activeImageIndex}
        titleText="unit-images"
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onOpenChanged={() => {
          setActiveImageIndex(null);
        }}
      />

      {isOpenDraftDialog && (
        <UnitStatusDraftDialog
          activeItem={activeData}
          isOpen={isOpenDraftDialog}
          reloadData={() => {
            setIsOpenDraftDialog(false);

            if (reloadData) reloadData();
          }}
          isOpenChanged={() => {
            setIsOpenDraftDialog(false);
          }}
        />
      )}
      <UnitStatusAvailableDialog
        activeItem={activeData}
        isOpen={isOpenAvailableDialog}
        reloadData={() => {
          setIsOpenAvailableDialog(false);
          getPendingUserFields();
          if (reloadData) reloadData();
        }}
        isOpenChanged={() => {
          setIsOpenAvailableDialog(false);
        }}
        fieldsNeedsApproval={fieldsNeedsApproval}
        loginResponse={loginResponse}
      />
      <DialogComponent
        isOpen={open}
        onCancelClicked={() => setOpen(false)}
        onCloseClicked={() => setOpen(false)}
        translationPath={translationPath}
        parentTranslationPath="UnitsView"
        titleText="ArchiveUnit"
        onSubmit={(e) => {
          e.preventDefault();
          archiveUnits();
        }}
        maxWidth="sm"
        dialogContent={
          <span>{t(`${translationPath}MassageArchiveUnits`)}</span>
        }
      />
      {addActivity && (
        <ActivitiesManagementDialog
          open={addActivity}
          onSave={() => {
            setAddActivity(false);
          }}
          close={() => {
            setAddActivity(false);
          }}
          unitOperationId={activeData?.operation_type?.lookupItemId}
          unitSaleTypeId={activeData?.sale_type?.lookupItemId}
          unitTypeId={activeData?.unit_type?.lookupItemId}
          actionItemId={activeData?.id}
          translationPath={""}
          parentTranslationPath={"ActivitiesView"}
        />
      )}
      <PopoverComponent
        attachedWith={attachedWith}
        handleClose={() => setAttachedWith(null)}
        component={<AgentListingDetails />}
        idRef="agent-listing-details"
        anchorOrigin={{
          vertical: "top",
          horizontal: "top",
        }}
        // transformOrigin={{
        //   vertical: 'left',
        //   horizontal: 'left',
        // }}
      />
    </div>
  );
}

CardDetailsComponent.propTypes = {
  activeData: PropTypes.instanceOf(Object),
  reloadData: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string,
  from: PropTypes.number,
  cardDetailsActionClicked: PropTypes.func,
  displyOpenFileButton: PropTypes.bool,
};
CardDetailsComponent.defaultProps = {
  translationPath: 'utilities.cardDetailsComponent.',
  activeData: null,
  from: 1,
  cardDetailsActionClicked: () => { },
  displyOpenFileButton: false,
};
export { CardDetailsComponent };
