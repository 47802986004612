import React, { useEffect, useState, useCallback, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { ButtonBase } from "@material-ui/core";
import Joi from "joi";
import {
  DialogComponent,
  PhonesComponent,
  Spinner,
  AutocompleteComponent,
} from "../../../../../../../../../../../Components";
import {
  showError,
  getErrorByName,
  GlobalTranslate,
  showSuccess,
} from "../../../../../../../../../../../Helper";
import {
  AddBranchExtensionNumberAPI,
  GetAllMainNumbersByBranchIdAPI,
  ImportExtensionsNumbersAPI,
} from "../../../../../../../../../../../Services";
import { ActivityFilesUploader } from "../../../../../../../../../../../Components/UploaderActivitieFileComponent/ActivityFilesUploader";

export const AddAndEditExtension = ({
  parentTranslationPath,
  translationPath,
  isOpen,
  onClose,
  onSave,
  branchId,
  activeItem,
  reloadAllExtensions,
  setReloadAllExtensions,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState({
    allMainNumbersByBranchID: false,
    saveExtension: false,
  });
  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };
  const [state, setState] = useReducer(reducer, {
    files: [],
    binaryFile: [],
  });
  const [allMainNumbersByBranchID, setAllMainNumbersByBranchID] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [helperText, setHelperText] = useState({
    extensionNumber: "",
  });

  const [extensionInfo, setExtensionInfo] = useState({
    mainNumberId: null,
    mainNumber: null,
    extensionNumber: null,
    branchExtensionId: null,
    isActive: false,
  });
  const schema = Joi.object({
    mainNumberId: Joi.number()
      .required()
      .messages({
        "number.base": t(`${translationPath}main-number-is-required`),
        "number.empty": t(`${translationPath}main-number-is-required`),
        "number.required": t(`${translationPath}main-number-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(extensionInfo);

  const saveHandler = useCallback(async () => {
    setIsLoading((item) => ({ ...item, saveExtension: true }));
    let file = null;
    if (state.binaryFile && state.binaryFile.length) {
      for (let newFile of state.binaryFile) {
        file = newFile;
      }
    }

    const res =
      state.files && state.files.length === 0
        ? await AddBranchExtensionNumberAPI(
            extensionInfo.mainNumberId,
            extensionInfo.extensionNumber
          )
        : await ImportExtensionsNumbersAPI(extensionInfo?.mainNumberId, {
            file,
          });
    if (!(res && res.status && res.status !== 200)) {
       showSuccess(state.files && state.files.length ? t(`${translationPath}add-extensions-succssfuly`) : t(`${translationPath}add-extension-succssfuly`) );
      setIsLoading((item) => ({ ...item, saveExtension: false }));
      onSave(res);
      setReloadAllExtensions(!reloadAllExtensions);
    } else {
      if(state.files && state.files.length)
      showError(state.files && state.files.length ? t(`${translationPath}add-extensions-faild`) : t(`${translationPath}add-extension-faild`) );
     else 
      setIsLoading((item) => ({ ...item, saveExtension: false }));
    }
  }, [state.binaryFile, extensionInfo]);

  const convoloNumberValidation = (extensionNumber) => {
    if (extensionNumber.length < 11) {
      setHelperText({
        ...helperText,
        extensionNumber: t(
          `${translationPath}enter-extension-number-at-least-11-digits`
        ),
      });
      return true;
    } else if (extensionNumber.length > 14) {
      setHelperText({
        ...helperText,
        extensionNumber: t(
          `${translationPath}enter-extension-number-no-more-than-14-digits`
        ),
      });
      return true;
    } else {
      setHelperText({
        ...helperText,
        extensionNumber: "",
      });
      return false;
    }
  };
  const GetAllMainNumberByBranchId = useCallback(async () => {
    setIsLoading((item) => ({ ...item, allMainNumbersByBranchID: true }));

    const res = await GetAllMainNumbersByBranchIdAPI(branchId);
    if (!(res && res.status && res.status !== 200)) {
      setAllMainNumbersByBranchID(res);
    } else setAllMainNumbersByBranchID([]);

    setIsLoading((item) => ({ ...item, allMainNumbersByBranchID: false }));
  }, [branchId]);

  useEffect(() => {
    if (branchId) {
      GetAllMainNumberByBranchId();
    }
  }, [branchId]);

  useEffect(() => {
    if (!isOpen) {
      setExtensionInfo({
        mainNumberId: null,
        mainNumber: null,
        extensionNumber: null,
        branchExtensionId: null,
        isActive: false,
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (state.files && state.files.length)
      setHelperText((item) => ({ ...item, extensionNumber: "" }));
  }, [state.files]);

  return (
    <>
      <DialogComponent
        saveText={t(`${translationPath}confirm`)}
        disableBackdropClick
        titleText={!activeItem ? t(`${translationPath}add-extension`) : t(`${translationPath}edit-extension`)}
        saveClasses={"btns theme-solid bg-primary"}
        saveType="button"
        maxWidth="sm"
        dialogContent={
          <div className="d-flex-column-center">
            <Spinner isActive={isLoading.saveExtension} isAbsolute />
            <div className="w-100 mr-1-reversed mt-3">
              <AutocompleteComponent
                key={`mainNumber`}
                labelClasses="Requierd-Color"
                idRef={`mainNumberRef`}
                multiple={false}
                inputPlaceholder={t("select-main-number")}
                data={allMainNumbersByBranchID}
                inputClasses="inputs theme-form-builder"
                displayLabel={(option) =>
                  (option && option.branchMainNumber) || ""
                }
                onChange={(e, newValue) => {
                  setExtensionInfo((item) => ({
                    ...item,
                    mainNumberId: newValue?.branchMainNumberId,
                    mainNumber: newValue,
                  }));
                }}
                isLoading={isLoading.allMainNumbersByBranchID}
                withLoader
                withoutSearchButton
                labelValue={"main-number"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                onOpen={() => {
                  if (allMainNumbersByBranchID?.length === 0)
                    GetAllMainNumberByBranchId();
                }}
              />
            </div>
            <div className="w-100 mr-1-reversed mt-3">
              <PhonesComponent
                idRef="extensionNumberRef"
                labelValue="single-extension-number"
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                value={extensionInfo?.extensionNumber || ""}
                helperText={
                  (helperText?.extensionNumber !== "" &&
                    t(helperText?.extensionNumber)) ||
                  ""
                }
                error={helperText?.extensionNumber !== ""}
                inputPlaceholder="enter-valid-extension-number"
                isSubmitted={isSubmitted}
                onInputChanged={(value) => {
                  let newValue = value;
                  newValue = newValue.replaceAll(" ", "");
                  convoloNumberValidation(newValue);
                  setExtensionInfo((item) => ({
                    ...item,
                    extensionNumber: newValue,
                  }));
                }}
              />
            </div>
            <div className="w-100 d-flex-column mb-3 mt-3 b-bottom">
              <div className="d-flex-center fj-start">
                <span className="mdi mdi-upload c-blue-lighter" />
                <span className="px-1 c-blue-lighter fw-bold">
                  {t(`${translationPath}or-bulk-upload`)}
                </span>
              </div>
              <div className="w-100 d-flex-column">
                <ActivityFilesUploader
                  state={state}
                  setState={setState}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  multiple={false}
                  accept={"xlsx/*"}
                  canSelectOneDocument={true}
                  getBinaryFile={(binaryFile) =>
                    setState({ id: "binaryFile", value: binaryFile })
                  }
                />
                <div className="d-flex d-flex-v-center-h-between">
                  <a
                    href='/files/BulkConvoloNumberTemplate.xlsx'
                    download='Bulk Convolo Number Template'
                    className='btns  btns c-danger fw-bold'
                  >
                    <span className='MuiButton-label'>
                      <span className='mx-2'>{t(`${translationPath}download-template`)}</span>
                    </span>
                  </a>
                  </div>
              </div>
            </div>
           </div>
        }
        isOpen={isOpen}
        saveIsDisabled={
          isLoading.saveExtension ||
          schema.error ||
          extensionInfo.mainNumberId === null ||
          (state.files &&
            state.files.length === 0 &&
            (extensionInfo.extensionNumber === null ||
              helperText.extensionNumber !== "")) ||
          (extensionInfo.extensionNumber === null &&
            state.files.length === 0) ||
          (state.files && state.files.length && false)
        }
        onSaveClicked={() => saveHandler()}
        onCancelClicked={onClose}
        translationPath={translationPath.confirm}
        onCloseClicked={onClose}
      />
    </>
  );
};
