import React, { useEffect, useMemo, useState } from 'react';
import { DialogComponent, Spinner } from '../../../../../Components';
import '../../../UnitsView/UnitStatusBreadcrumb/UnitStatusBreadcrumb.scss';
import { Avatar, Button } from '@material-ui/core';
import {
  getDownloadableLink,
  getFirstLastNameLetters,
  showError,
} from '../../../../../Helper';
import MarkUnitAvailableDialog from './MarkUnitAvailableDialog';
import { QuickPublishUnit } from '../../../../../Services';

function PublishToWebsiteDialog({
  isOpen: isDialogOpen,
  isOpenChanged: toggleDialog,
  translationPath,
  parentTranslationPath,
  isLoading,
  publishData,
  setPublishData,
  reloadData,
  isForLease,
}) {
  const [isMarkDialogOpen, setIsMarkDialogOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const unitImgURLs = useMemo(() => convertImageIdsToURLs(), [publishData]);

  function convertImageIdsToURLs() {
    return publishData?.images
      ?.flatMap((item) =>
        item?.files?.map((img) =>
          img.fileId
            ? getDownloadableLink(img.fileId)
            : getDownloadableLink(item)
        )
      )
      .filter(Boolean);
  }

  const handleEditPublish = () => {
    setIsMarkDialogOpen(true);
  };
  const UpdatePublishData = (updatedData) => {
    setPublishData(updatedData);
  };

  const handleQuickPublishUnit = async (event) => {
    event.preventDefault();
    const updatedPublishData = {
      ...publishData,
      isPublishUnitLease: isForLease ? true : false,
      isPublishUnitSale: !isForLease ? true : false,
    };
    try {
      await QuickPublishUnit(updatedPublishData);
      reloadData();
      toggleDialog();
    } catch {
      showError('An error occurred while processing your request.');
    }
  };

  useEffect(() => {
    const totalFilesCount = publishData?.images?.flatMap(
      (image) => image.files
    ).length;
    const hasRequiredFiles = totalFilesCount > 2;
    const hasMarketingFile = publishData?.images
      ?.flatMap((image) => image.files)
      .some((file) => file.isMarketing === true);
    setIsDisabled(
      !publishData?.titleEn ||
        !publishData?.descriptionEn ||
        !publishData?.listingAgentId ||
        !hasRequiredFiles
    );
  }, [publishData]);

  if (!publishData) {
    return null;
  }
  return (
    <>
      <DialogComponent
        titleText={'Publish to Website'}
        maxWidth='sm'
        isOpen={isDialogOpen}
        dialogContent={
          <>
            <Spinner isActive={isLoading} />
            <div className='publish-dialog-wrapper'>
              <div className='publish-field'>
                <p className='publish-filed-label'>
                  {isForLease ? 'Rent Price' : 'Selling price'}
                </p>
                <span className='publish-filed-text'>
                  {isForLease
                    ? publishData?.rentPerYearPrice
                    : publishData?.sellingPrice}
                </span>
              </div>
              <div className='publish-field'>
                <p className='publish-filed-label'>
                  Marketing title in English
                </p>
                <span className='publish-filed-text'>
                  {publishData?.titleEn}
                </span>
              </div>
              <div className='publish-field'>
                <p className='publish-filed-label'>Marketing title in Arabic</p>
                <span className='publish-filed-text'>
                  {publishData?.titleAr}
                </span>
              </div>
              <div className='publish-field'>
                <p className='publish-filed-label'>
                  Marketing description in English{' '}
                </p>
                <span className='publish-filed-text'>
                  {publishData?.descriptionEn}
                </span>
              </div>
              <div className='publish-field'>
                <p className='publish-filed-label'>
                  Marketing description in Arabic{' '}
                </p>
                <span className='publish-filed-text'>
                  {publishData?.descriptionAr}
                </span>
              </div>
              <div className='d-flex-v-center'>
                <Avatar className='agent-avatar'>
                  {getFirstLastNameLetters(publishData?.listingAgentName || '')}
                </Avatar>
                <div className='agent-avatar-text'>
                  <span>{publishData.listingAgentName || '-'}</span>
                </div>
              </div>
              <div>
                <p className='publish-filed-label'>Marketing Images</p>
                <div className='publish-img-wrapper'>
                  <div className='publish-img'>
                    {unitImgURLs[0] && (
                      <img
                        src={unitImgURLs[0]}
                        alt='unit-image'
                        className='image-width'
                      />
                    )}
                  </div>
                  <div className='publish-img'>
                    {unitImgURLs[1] && (
                      <img
                        src={unitImgURLs[1]}
                        alt='unit-image'
                        className='image-width'
                      />
                    )}
                  </div>
                  <div className='publish-img'>
                    {unitImgURLs[2] && (
                      <img
                        src={unitImgURLs[2]}
                        alt='unit-image'
                        className='image-width'
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className='publish-action-wrapper'>
                <Button
                  className='btns'
                  onClick={() => toggleDialog()}
                >
                  Cancel
                </Button>
                <div className='publish-action-left-side'>
                  <Button
                    className='btns edit-btn'
                    onClick={handleEditPublish}
                  >
                    Edit
                  </Button>
                  <Button
                    className='btns theme-solid confirm-btn'
                    onClick={handleQuickPublishUnit}
                    disabled={isDisabled}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </div>
          </>
        }
      />

      {isMarkDialogOpen && (
        <MarkUnitAvailableDialog
          isOpen={isMarkDialogOpen}
          isOpenChanged={() => setIsMarkDialogOpen(false)}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          publishActiveItem={publishData}
          UpdatePublishData={UpdatePublishData}
          isForLease={isForLease}
        />
      )}
    </>
  );
}

export default PublishToWebsiteDialog;
