import React, { useEffect, useState } from 'react';
import './UnitStatusBreadcrumb.scss';
import { UnitStatusDraftDialog } from '../../UnitsSalesView/UnitsSalesUtilities/CardDetailsComponent/Dialogs';
import { UnitsStatusEnum } from '../../../../Enums';
import { useHistory } from 'react-router-dom';
import MarkUnitAvailableDialog from '../UnitsUtilities/Dialogs/MarkUnitAvailableDialog';
import PublishToWebsiteDialog from '../UnitsUtilities/Dialogs/PublishToWebsiteDialog';
import {
  CanMakeQuickActions,
  GetUnitQuickPublishInfo,
} from '../../../../Services';
import { getIsAllowedPermission, showError } from '../../../../Helper';
import { UnitsSalesPermissions } from '../../../../Permissions';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TransactionUnitSelectionDialog } from '../../LeaseTransactionJourney/TransactionSteps';

const steps = [
  {
    label: 'Move to draft',
    key: 'draft',
    permissionId:
      UnitsSalesPermissions.MakeTransactionOnUnitSaleToBeDraft.permissionsId,
  },
  {
    label: 'Mark as available',
    key: 'available',
    permissionId:
      UnitsSalesPermissions.MakeTransactionOnUnitSaleToBeAvailable
        .permissionsId,
  },
  {
    label: 'Publish to website',
    key: 'publish',
    permissionId:
      UnitsSalesPermissions.EditPublishedToWebsiteMarketing.permissionsId,
  },
  {
    label: 'Make transaction',
    key: 'transaction',
    permissionId: [
      UnitsSalesPermissions.SetUnitAsSale.permissionsId,
      UnitsSalesPermissions.SetUnitAsReserveForSale.permissionsId,
    ],
  },
];

const dialogComponents = {
  draft: UnitStatusDraftDialog,
  available: MarkUnitAvailableDialog,
  publish: PublishToWebsiteDialog,
};

function UnitStatusBreadcrumb({
  unitData,
  reloadData,
  translationPath,
  parentTranslationPath,
  isForLease,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const [activeDialog, setActiveDialog] = useState(null);
  const [publishData, setPublishData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isTransactionDialogOpen, setIsTransactionDialogOpen] = useState(false);
  const history = useHistory();
  const loginResponse = useSelector((state) => state.login.loginResponse);

  const clickHandler = async (key, permissions) => {
    const result = await CanMakeQuickActions({
      operationTypeId: unitData?.operationType,
      unitId: unitData?.id,
    });
    const isPermitted = getIsAllowedPermission(
      Object.values(UnitsSalesPermissions),
      loginResponse,
      permissions
    );
    if (!(isPermitted || result)) {
      showError(t('action_not_allowed'));
      return;
    }
    if (key === 'transaction') {
      if (isForLease) {
        setIsTransactionDialogOpen(true);
      } else {
        history.push(
          `/home/units-sales/unit-transaction-parameters?unitRefNo=${unitData?.unit_ref_no}`
        );
      }
    } else {
      setActiveDialog((prev) => (prev === key ? null : key));
    }
  };

  const getUnitPublishInfo = async () => {
    setIsLoading(true);
    const unitId = unitData && unitData?.id;
    const operationType = unitData && unitData?.operationType;
    try {
      const res = await GetUnitQuickPublishInfo(unitId, operationType);
      setPublishData(res);
    } catch {
      showError('An error occurred while processing your request.');
    } finally {
      setIsLoading(false);
    }
  };

  const ActiveDialogComponent = activeDialog
    ? dialogComponents[activeDialog]
    : null;

  useEffect(() => {
    if (unitData) {
      getUnitPublishInfo();
    }
  }, [unitData]);

  return (
    <>
      <ul className='status-breadcrumb'>
        {steps.map(({ label, key, permissionId }) => {
          let isDisabled = false;
          const statusKeyToCheck = isForLease
            ? unitData?.leaseUnitStatusDetails?.key
            : unitData?.unitStatusDetails?.key;
          if (key === 'draft') {
            if (isForLease) {
              isDisabled = ![
                UnitsStatusEnum.Leased.key,
                UnitsStatusEnum.Available.key,
                UnitsStatusEnum.ReservedLeased.key,
              ].includes(statusKeyToCheck);
            } else {
              isDisabled = ![
                UnitsStatusEnum.Available.key,
                UnitsStatusEnum.Sale.key,
                UnitsStatusEnum.ReservedSale.key,
              ].includes(statusKeyToCheck);
            }
          } else if (key === 'available') {
            if (isForLease) {
              isDisabled = ![
                UnitsStatusEnum.Draft.key,
                UnitsStatusEnum.Leased.key,
                UnitsStatusEnum.ReservedLeased.key,
              ].includes(statusKeyToCheck);
            } else {
              isDisabled = ![
                UnitsStatusEnum.Draft.key,
                UnitsStatusEnum.Sale.key,
                UnitsStatusEnum.ReservedSale.key,
              ].includes(statusKeyToCheck);
            }
          } else if (key === 'transaction') {
            const leaseStatus =
              unitData?.leaseUnitStatusDetails?.name?.toLowerCase();
            const unitStatus = unitData?.unitStatusDetails?.name?.toLowerCase();
            if (isForLease) {
              isDisabled = leaseStatus !== 'available';
            } else {
              isDisabled = !['available', 'draft'].includes(unitStatus);
            }
          } else if (key === 'publish') {
            isDisabled =
              unitData?.isPublishUnitSale === true ||
              unitData?.isPublishUnitLease === true ||
              ![
                UnitsStatusEnum.Available.key,
                UnitsStatusEnum.Draft.key,
              ].includes(statusKeyToCheck);
          }
          return (
            <li
              className={`breadcrumb-contact ${
                isDisabled ? 'breadcrumb-contact-disabled' : ''
              } `}
              key={key}
            >
              <div
                className={`breadcrumb-value ${
                  isDisabled ? 'breadcrumb-disabled' : ''
                } `}
                onClick={() => clickHandler(key, permissionId)}
              >
                <span className='breadcrumb-text'>{label}</span>
              </div>
            </li>
          );
        })}
      </ul>

      {ActiveDialogComponent && (
        <ActiveDialogComponent
          activeItem={unitData}
          isOpen={ActiveDialogComponent}
          isOpenChanged={() => setActiveDialog(null)}
          breadcrumbStatus={true}
          reloadData={() => {
            setActiveDialog(null);
            if (reloadData) reloadData();
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isLoading={isLoading}
          publishData={publishData}
          setPublishData={setPublishData}
          isForLease={isForLease}
        />
      )}

      {isTransactionDialogOpen && (
        <TransactionUnitSelectionDialog
          isDialogOpen={isTransactionDialogOpen}
          onClose={() => {
            setIsTransactionDialogOpen(false);
          }}
          activeUnitId={unitData?.id}
        />
      )}
    </>
  );
}

export default UnitStatusBreadcrumb;
