import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { AddAndEditMainNumbersDialog } from "../AddAndEditMainNumbers";
import { ReactComponent as EditIcon } from "../../../../../../../../../../../assets/images/defaults/edit-01.svg";
import { Spinner } from "../../../../../../../../../../../Components";

import {
  GetBranchInfoById,
} from "../../../../../../../../../../../Services";

export const MainNumbersCardsView = ({
  parentTranslationPath,
  translationPath,
  branchId,
  isReleadData,
  setIsReleadData,
  onCardClick,
  setIsLoading , 
  mainNumbersByBranchIdInfo , 
  setMainNumbersByBranchIdInfo , 
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isOpenMainNumberDialog, setIsOpenMainNumberDialog] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  function formatNumber(num) {
    let str = num.toString();
    let countryCode = str.slice(0, 3); // "971"
    let middlePart = str.slice(3, 4); // "0" (converted to "-2")
    let remainingPart = str.slice(4); // "9876543" (should be "12345678" in your example)


    return `${countryCode}-${middlePart}-${remainingPart}`;
  }


  const GetBranchInfoByIdAPI = useCallback(async () => {
    setIsLoading((item)=> ({...item , branchInfo : true   }));
    const res = await GetBranchInfoById(branchId);
    if (res) setMainNumbersByBranchIdInfo({ ...res });
    setIsLoading((item)=> ({...item , branchInfo : false    }));

  }, [branchId, isReleadData]);

  useEffect(() => {
    if (branchId) {
      GetBranchInfoByIdAPI();
    }
  }, [branchId, isReleadData]);


  return (
    <>
      {mainNumbersByBranchIdInfo &&
        mainNumbersByBranchIdInfo.branchMainNumbers &&
        mainNumbersByBranchIdInfo.branchMainNumbers.map((item, index) => (
          <div
            className="px-2 mt-2 mb-2"
            key={`mainNumber${index + 1}`}
            onClick={() => {
              console.log("mainNumberId", index);
            }}
          >
            <div
              className="main-number-card"
              onClick={() => onCardClick(item)}
            >
              <div className="main-number-info">
                <span className="fw-bold">
                  {" "}
                  {(item.mainNumber &&
                    item.mainNumber.length > 4 &&
                    formatNumber(item.mainNumber)) ||
                    item.mainNumber}
                </span>
                <span
                  onClick={() => {
                    setIsOpenMainNumberDialog(true);
                    setActiveItem(item);
                  }}
                  title={t(`${translationPath}edit-main-number`)}
                >
                  <EditIcon />
                </span>
              </div>
              <div className="main-number-info">
                <span className="main-sub-data">
                  {" "}
                   {t(`${translationPath}total-extension`)}
                    :{" "}
                  <span className="px-1 info">{item.totalExtension}</span>
                </span>
                <span> {""} </span>
              </div>
              <div className="main-number-info">
                <span className="main-sub-data">
                  {" "}
                  {t(`${translationPath}active`)}
                  : <span className="px-1 active"> {item.active}</span>
                </span>
                <span className="main-sub-data">
                  {" "}
                  {t(`${translationPath}inactive`)}

                  : <span className="px-1 inactive"> {item.inActive} </span>
                </span>
              </div>
              <div className="main-number-info">
                <span className="main-sub-data">
                  {" "}
                  {t(`${translationPath}assigned`)}
                   :{" "}
                  <span className="px-1 info"> {item.assigned}</span>{" "}
                </span>
                <span className="main-sub-data">
                  {" "}
                  {t(`${translationPath}unassigned`)}

                  :{" "}
                  <span className="px-1 info">
                    {" "}
                    {item.unAssigned}{" "}
                  </span>{" "}
                </span>
              </div>
            </div>
          </div>
        ))}
      {isOpenMainNumberDialog && (
        <AddAndEditMainNumbersDialog
          isOpen={isOpenMainNumberDialog}
          onClose={() => {
            setIsOpenMainNumberDialog(false);
            setActiveItem(null);
          }}
          onSave={(status) => {
            setIsOpenMainNumberDialog(false);
            setActiveItem(null);
            if (status) setIsReleadData(!isReleadData);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          activeItem={activeItem}
        />
      )}
    </>
  );
};
